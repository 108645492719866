










































































































































































































































































































































































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
// import debounce from 'lodash.debounce';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { Debounce } from 'vue-debounce-decorator';
import debounce from 'lodash.debounce';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import isOnline from 'is-online';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import { TASQS_LISTS } from '@/lib/constants';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    PadItem: () => getComponent('tasqs/PadItem'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SwipeOut,
    Multiselect,
    SwipeList,
  },
})
export default class TasqsVerticalList extends mixins() {
  @Prop({ type: Boolean, default: false }) isMobileView!: boolean;

  filterOpen = false;

  dataLoading = false

  isOnlineApp = false;

  completedVisible = false;

  selectedUsernames: any = []

  selectedRoutes: any = [];

  selectedTeams: any = []

  cycleTimeGreaterThanFilter: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  filterVal = '';

  activeList = 'ALL';

  showSearch = false;

  registerScrollEvent = false;

  registerChangeEvent = false;

  showInfiniteScrollLoading = false;

  typesOptions = [] as any;

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  get isLoadingTasqs() {
    this.sleep(500);
    return tasqsListModule.isLoadingTasqs;
  }

  get isLoadingSearchTasqs() {
    return tasqsListModule.isLoadingSearchTasqs;
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    }));
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get tasqListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  get listOptions() {
    return TASQS_LISTS;
  }

  itemClick(e) {
    tasqsListModule.setShowTasqDetailsMobile(true);
  }

  get moreTotalTasqs() {
    return tasqsListModule.todaysTasqLength;
  }

  get morePaginatedData() {
    return (tasqsListModule.currentTasqListPageNo <= tasqsListModule.tasqTotalPages) || tasqsListModule.tasqStartLimit === 0;
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get producingWells(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enableRoutes(){
    return scheduleModule.enabledRoutes.map(r => {
      return r.Route
    })
  }

  get paginationHeight() {
    return tasqsListModule.infiniteScrollHeight;
  }

  get filteredCompletedTasqs(): TasqJob[] {
    let tasqs = this.completedTasqs;
    if (this.filterVal) {
      tasqs = this.completedTasqs.filter(
        (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
      );
    }
    return tasqs.filter((t) => this.typesOptions.includes(t.overriddenName));
  }

  get filteredProducingWells(): any[] {
	  let totalProducingWells: any[] = [];
	  for (let x = 0; x < this.enabledWells.length; x++) {
      let addToProducingWells = true;
      for (let y = 0; y < this.tasqs.length; y++) {
        if (this.tasqs[y].wellName == this.enabledWells[x].wellName) {
          addToProducingWells = false;
        }
      }
      if (addToProducingWells) {
        totalProducingWells.push(this.enabledWells[x]);
      }
	  }
    totalProducingWells = totalProducingWells.filter(
      (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase()),
    );
	  return totalProducingWells;
  }

  get filteredTasqs(): TasqJob[] {
    const { tasqs } = this;
    // if (this.filterVal) {
    //   tasqs = this.tasqs.filter(
    //     (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
    //   );
    // }

    return tasqs.filter((t) => this.typesOptions.includes(t.overriddenName));
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  get progressPercentage() {
    return tasqsListModule.progressPercentage;
  }

  addTasq() {
	  this.$emit('add-tasq');
  }

  editTasqs() {
	  this.$emit('edit-tasq');
  }

  async reassignTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reassign-tasq');
  }

  async snoozeTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('snooze-tasq');
  }

  async rejectTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reject-tasq');
  }

  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }

  nameWithUnits(name) {
    return `${name}`;
  }

  setTasqListLevel(level) {
    this.filterOpen = false;
    if (!this.isLoadingTasqs) {
      tasqsListModule.resetPagination();
      tasqsListModule.setTasqListLevel(level);
      this.sleep(500).then(() => {
        this.changeTasqList();
      });
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  closeFilterDialog() {
    this.filterOpen = false;
  }

  showSearchSelected() {
    this.showSearch = true;

    this.sleep(500).then(() => {
	  // @ts-ignore
	  this.$refs.searchTextFieldRef.focus();
    });
  }

  get teams() {
    return workflowModule.teams;
  }

  @Debounce(100)
  async changeTasqList() {
    if (!this.registerChangeEvent) {
      this.registerChangeEvent = true;
      this.filterOpen = false;
      const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};
      localStorage.setItem('tasqActiveList', JSON.stringify(list));
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(false);
      tasqsListModule.setIsBatchResponding(false);
      tasqsListModule.setIsBatchReassign(false);
      // tasqsListModule.setActiveTasq('');
      tasqsListModule.resetChekedTasqs();
      tasqsListModule.setActiveFilterList(list);
      tasqsListModule.setSubstringFilter(this.filterVal);
      tasqsListModule.setPredictionTypeFilter(this.typesOptions);
      tasqsListModule.resetPagination();
      await tasqsListModule.getTasqsByPagination(list);
      // this.$router.push({ name: 'Tasqs' });
      this.sleep(1000).then(() => {
        this.registerChangeEvent = false;
      });
    }
  }

  async resetFiltering() {
    this.selectUsername([]);
    this.selectedUsernames = [];
    this.typesOptions = [];
    this.selectedTeams = [];
    await this.changeTasqList();
  }

  async applyFiltering() {
    localStorage.setItem('tasqActiveOptions', JSON.stringify(this.typesOptions));
    this.selectUsername(this.selectedUsernames.map((u) => u.value));
    console.log(this.selectedRoutes)
    console.log(this.selectedTeams)
    console.log(this.unitsGreaterThanFilter)
    console.log(this.unitsLessThanFilter)
    console.log(this.cycleTimeGreaterThanFilter);
    console.log(this.cycleTimeLessThanFilter)
    // tasqsListModule.setCycleTimeDaysFilter(this.cycleTimeDays);
    // tasqsListModule.setDefermentDaysFilter(this.defermentDays);
    
    tasqsListModule.setJobTypeFilter(this.typesOptions);
    tasqsListModule.setActiveTeamFilter(this.selectedTeams);
    this.filterOpen = false;
    await this.changeTasqList();
  }

  selectUsername(users) {
    tasqsListModule.setUsernamesBulkList(users);
    localStorage.setItem('TasqUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));

  }

  @Watch('filterVal', { immediate: true })
  @Debounce(2000)
  updateFilterVal() {
    localStorage.setItem('tasqFilterValue', this.filterVal);
    this.changeTasqList();
  }

  paginationHandler({ target: { scrollTop, clientHeight, scrollHeight } }) {
    if (!this.completedVisible) {
      this.showInfiniteScrollLoading = true;
      if (Math.ceil(scrollTop + clientHeight + 150) >= scrollHeight && !this.registerScrollEvent) {
        this.registerScrollEvent = true;
        tasqsListModule.setInfiniteListScroll(scrollHeight);
        const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};
        tasqsListModule.getTasqsByPagination(list);
        this.sleep(5000).then(() => {
          // const infiniteList = document.getElementById('infinite-list')!;
          // infiniteList.scrollTo(0, scrollHeight);
          this.showInfiniteScrollLoading = false;
          this.registerScrollEvent = false;
        });
      }
    }
  }

  async fetchTasq(tasq) {
    await tasqsListModule.setActiveTasqExplicit(tasq);
  }

  async created() {
    // tasqsListModule.setUsernamesList([])
    this.selectUsername([]);
    this.selectedUsernames = [];
    this.isOnlineApp = await isOnline();
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setActivePage('Tasq');
    await accountModule.getReassignmentList();
    await workflowModule.listTeams();
    const savedActiveList = localStorage.getItem('tasqActiveList');
    const savedActiveOptions: any = localStorage.getItem('tasqActiveOptions');
    const savedFilterValue: any = localStorage.getItem('tasqFilterValue');
    let tasqUsernamesFilter: any = localStorage.getItem('TasqUsernamesFilter')

    if (tasqUsernamesFilter && JSON.parse(tasqUsernamesFilter).length) {
      this.selectedUsernames = []
      tasqUsernamesFilter =  JSON.parse(tasqUsernamesFilter)
      this.availableUsers.forEach((i) => {
        if (tasqUsernamesFilter?.includes(i.value)) {
          this.selectedUsernames.push(i)
        }
      })
      tasqsListModule.setUsernamesBulkList(tasqUsernamesFilter)
    }else {
      this.selectedUsernames = (this.availableUsers.find(u=> u.value === accountModule.user.email))
    }
    if (savedFilterValue) {
      this.filterVal = savedFilterValue;
    }
    if (savedActiveList && JSON.parse(savedActiveList)) {
      this.activeList = JSON.parse(savedActiveList).val;
    }
    if (JSON.parse(savedActiveOptions)) {
      this.typesOptions = JSON.parse(savedActiveOptions);
    }
    await scheduleModule.getEnabledRoutes()
  }

  @Watch('isLoadingTasqs')
  @Debounce(500)
  async updateTasqsValue(value) {
    // @ts-ignore
    const scrollDivId = localStorage.getItem('ActiveTasqScroll');
    if (value === false) {
      if (tasqsListModule.kanbanSelectedTasq) {
        tasqsListModule.setKanbanSelectedTasq('');
      }
    }
    this.sleep(2000).then(() => {
      if (scrollDivId && scrollDivId.length) {
        const activeTasqDiv = document.getElementById(scrollDivId)!;
        if (activeTasqDiv) {
          activeTasqDiv.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
          localStorage.setItem('ActiveTasqScroll', '');
        }
      }
    });
  }
}
